<script lang="ts">
  import type { FacetValue } from "../facet-value";
  import type { Facet, FacetOptions } from "./type.ts";
  import { List } from "../facet-value-group";

  import type { ClassValue } from "svelte/elements";
  import { addNameAndFeatureTracking, isNotSelected, isSelected } from "./facetValues.ts";
  import { featureTrackingAttributes } from "../tracking";
  import SearchBox from "../input/SearchBox.svelte";
  import { setFilterTitleMode } from "../filter/filterTitleHack.ts";
  import type { FacetValueStatus } from "../facet-value/type.ts";

  interface Props extends Facet, FacetOptions {
    class?: ClassValue;
    scrollable?: boolean;
    searchable?: boolean;
  }

  let {
    name,
    values,
    popularValues = [], /*                                                                                                     */
    popularValuesMap = {},
    featureTracking,
    visibility,
    contentSnippet = defaultList, /*                     */
    scrollable = true,
    searchable = false,
    filterTitleMode = "onlyTitle",
    mode = "filterAccordion",
    parentId = "",
    ...rest
  }: Props = $props();

  /*                                           */
  const id = (featureTracking && `facet_${featureTracking.id}`) || "facet"; /*                                                               */
  const popValStringList = popularValuesMap[featureTracking?.id || ""] || []; /*                                                               */
  popularValues = popValStringList.map((id) => values.filter((val) => val.value === id)).flat();

  /*                                                                        */
  const popValLength = popularValues.length > 5 ? 5 : popularValues.length;
  /*                         */
  let popularFacetValues = $derived(
    popularValues.length >= 3 ? popularValues.slice(0, popValLength).map(addNameAndFeatureTracking(id, name, 1)) : [],
  );

  /*                                                                                                    */
  const hidden = visibility === "hidden";

  let filtered: string[] = $state([]);
  let searchTerm: string = $state("");

  /*                                               */
  const facetValuesWithoutPopular = $derived(
    values.filter((value) => !popularFacetValues.find((popVal) => popVal.id === value.id)),
  );
  const selectedValues = $derived(
    popularFacetValues
      .filter(isSelected)
      .map(addNameAndFeatureTracking(id, name, 1))
      .concat(facetValuesWithoutPopular.filter(isSelected).map(addNameAndFeatureTracking(id, name, 1))),
  );
  const unselectedPopularValues: FacetValue[] = $derived(
    searchTerm !== ""
      ? []
      : popularFacetValues.filter(isNotSelected).map(addNameAndFeatureTracking(id, name, selectedValues.length + 1)),
  );
  const unselectedValues: FacetValue[] = $derived(
    searchTerm === ""
      ? facetValuesWithoutPopular
          .filter(isNotSelected)
          .map(addNameAndFeatureTracking(id, name, unselectedPopularValues.length + selectedValues.length + 1))
          .filter((value: FacetValue) => !searchable || filtered.includes(value.title))
          .map((value: FacetValue) => ({ ...value, highlighted: searchTerm }))
      : popularFacetValues
          .concat(facetValuesWithoutPopular)
          .filter(isNotSelected)
          .map(addNameAndFeatureTracking(id, name, selectedValues.length + 1))
          .filter((value: FacetValue) => !searchable || filtered.includes(value.title))
          .map((value: FacetValue) => ({ ...value, highlighted: searchTerm })),
  );

  $effect(() => {
    /*                                                             */
    if (filterTitleMode === "onlyPopularValuesAndTitle" && popularValues.length < 3) {
      setFilterTitleMode(parentId, "onlyTitle");
    } else {
      setFilterTitleMode(parentId, filterTitleMode);
    }
  });
</script>

{#snippet defaultList(values: FacetValue[], valueStatus: FacetValueStatus)}
  <List {values} {valueStatus}></List>
{/snippet}

<fieldset
  {id}
  {name}
  class={["hc_facet", rest.class]}
  data-facet-visibility={visibility}
  {...featureTrackingAttributes(featureTracking)}
  {hidden}
>
  {@render contentSnippet(selectedValues, "selected")}
  {#if searchable}
    <div class="hc_searchbox_wrapper">
      <SearchBox
        label="Nach Marke suchen"
        emptyMessage="Nichts gefunden – versuch’ einen anderen Begriff"
        items={facetValuesWithoutPopular.concat(popularFacetValues).map((value: FacetValue) => value.title)}
        bind:value={searchTerm}
        bind:filtered
      />
    </div>
  {/if}
  {#if mode !== "detailSheet" && popularFacetValues.length > 0}
    <div class={["hc_facet__scrollBox", { "hc_facet__scrollBox--scrollable": scrollable }]}>
      {#if mode === "filterTitle" && filterTitleMode === "onlyPopularValuesAndTitle"}
        {@render contentSnippet(popularFacetValues, "unselectedPopular")}
      {:else}
        <span class="hc_facet__section_divider">BELIEBT</span>
        {@render contentSnippet(unselectedPopularValues, "unselectedPopular")}
        <span class="hc_facet__section_divider">WEITERE</span>
        {@render contentSnippet(unselectedValues, "unselected")}
      {/if}
    </div>
  {:else}
    <div class={["hc_facet__scrollBox", { "hc_facet__scrollBox--scrollable": scrollable }]}>
      {@render contentSnippet(unselectedValues, "unselected")}
    </div>
  {/if}
</fieldset>

<style lang="scss">
  fieldset {
    min-width: 0;
  }

  .hc_searchbox_wrapper {
    margin: 16px 0;
  }

  .hc_facet__section_divider {
    font-family: "OttoSans", Arial, Helvetica, sans-serif;
    color: #777;
    font-size: 0.75rem;
    line-height: 1.33333333333;
  }

  .hc_facet__scrollBox {
    &--scrollable {
      max-height: var(--scroll-area-height, 365px);
      overflow-y: auto;
      margin-left: calc(-2 * var(--oc-semantic-focus-outline-offset));
      padding-left: calc(2 * var(--oc-semantic-focus-outline-offset));
    }
  }
</style>
