<script module lang="ts">
  import type {
    FilterMode,
    FacetValueLabelType,
    FacetValueType,
    FacetValueGroupType,
    FacetType,
    ConfigurableFacetOptions,
    FacetValueStatus,
  } from "./type";

  const defaultFacetValueLabelType: FacetValueLabelType = "text";
  const defaultFacetValueLabelOptions: Partial<Record<FilterMode, FacetValueLabelOptions>> = {
    filterAccordion: { size: "125", titleSize: "100" },
    detailSheet: { fitContent: false, size: "125" },
  };
  const defaultFacetValueType: FacetValueType = "checkbox";
  const defaultFacetValueOptions: Partial<Record<FilterMode, FacetValueOptions>> = {};
  const defaultFacetValueGroupType: FacetValueGroupType = "list";
  const defaultFacetValueGroupOptions: Partial<Record<FilterMode, FacetValueGroupOptions>> = {};
  const defaultFacetType: FacetType = "scrollable";
  const defaultFacetOptions: Partial<Record<FilterMode, FacetOptions>> = {};
  const defaultFilterOptions: Partial<Record<FilterMode, FilterOptions>> = {
    filterAccordion: { filterTitleMode: "onlyTitle" }, /*                                                                                                                                          */
  };
</script>

<script lang="ts">
  import type { FacetValueLabelOptions } from "@otto-ec/heureka-components/facet-value-label";
  import type { FacetValue, FacetValueOptions } from "@otto-ec/heureka-components/facet-value";
  import type { FacetValueGroupOptions } from "@otto-ec/heureka-components/facet-value-group";
  import type { Facet, FacetOptions, FacetVisibility, PopularFacetValuesMap } from "@otto-ec/heureka-components/facet";
  import type { FilterOptions } from "@otto-ec/heureka-components/filter";
  import { Color, Rating, Text } from "@otto-ec/heureka-components/facet-value-label";
  import { Checkbox, Chip, Radio } from "@otto-ec/heureka-components/facet-value";
  import { Grid, List, OverflowBox } from "@otto-ec/heureka-components/facet-value-group";
  import { Scrollable, Simple } from "@otto-ec/heureka-components/facet";
  import type { Snippet } from "svelte";
  import { activeConfiguration } from "./stores.svelte";
  import { unpack } from "./type";

  interface Props extends ConfigurableFacetOptions {
    data: Facet;
    visibility: FacetVisibility; /*                                           */
    parentId: string;
    popularValues: PopularFacetValuesMap; /*                                           */
  }

  const {
    data,
    parentId = "find_filter_material",
    visibility,
    facetValueLabelConfig = {},
    facetValueConfig = {},
    facetValueGroupConfig = {},
    facetConfig = {},
    filterConfig = {},
  }: Props = $props();

  const mode = $derived(activeConfiguration.mode);
  const { type: facetValueLabelType = defaultFacetValueLabelType, ...userFacetValueLabelOptions } = $derived(
    unpack(facetValueLabelConfig, mode),
  );
  const { type: facetValueType = defaultFacetValueType, ...userFacetValueOptions } = $derived(
    unpack(facetValueConfig, mode),
  );
  const { type: facetValueGroupType = defaultFacetValueGroupType, ...userFacetValueGroupOptions } = $derived(
    unpack(facetValueGroupConfig, mode),
  );
  const { type: facetType = defaultFacetType, ...userFacetOptions } = $derived(unpack(facetConfig, mode));
  const { ...userFilterOptions } = $derived(unpack(filterConfig, mode));

  /*                                 */
  const facetValueLabelOptions: FacetValueLabelOptions = $derived({
    ...defaultFacetValueLabelOptions[mode],
    ...(facetValueLabelType === "color" && { size: "100" }),
    ...userFacetValueLabelOptions,
  });

  const facetValueLabelMap: Record<FacetValueLabelType, Snippet<[FacetValue]>> = {
    color,
    rating,
    text,
  };

  /*                           */
  const facetValueOptions: FacetValueOptions = $derived({
    ...defaultFacetValueOptions[mode],
    forceAriaLabel: facetValueLabelType === "rating",
    ...userFacetValueOptions,
  });

  const facetValueMap: Record<FacetValueType, Snippet<[FacetValue]>> = {
    checkbox,
    chip,
    radio,
  };

  /*                                 */
  const facetValueGroupOptions: FacetValueGroupOptions = $derived({
    ...defaultFacetValueGroupOptions[mode],
    item: facetValueMap[facetValueType],
    ...userFacetValueGroupOptions,
  });

  const facetValueGroupMap: Record<FacetValueGroupType, Snippet<[FacetValue[], FacetValueStatus | undefined]>> = {
    grid,
    list,
    overflowBox,
  };

  /*                     */
  const facetOptions: FacetOptions = $derived({
    ...defaultFacetOptions[mode],
    contentSnippet: facetValueGroupMap[facetValueGroupType],
    ...userFacetOptions,
    visibility,
  });

  const facetMap: Record<FacetType, Snippet<[Facet]>> = {
    scrollable,
    simple,
  };

  /*                      */
  const filterOptions: FilterOptions = $derived({
    ...defaultFilterOptions[mode],
    ...userFilterOptions,
  });

  /*       */
  const label: Snippet<[FacetValue]> = $derived(facetValueLabelMap[facetValueLabelType]);
  const facet: Snippet<[Facet]> = $derived(facetMap[facetType]);

  /*       */
  const featureTracking = { ...data.featureTracking, parentId };
</script>

{#snippet color(facetValue: FacetValue)}
  <Color {...facetValue} {...facetValueLabelOptions} />
{/snippet}

{#snippet rating(facetValue: FacetValue)}
  <Rating {...facetValue} {...facetValueLabelOptions} />
{/snippet}

{#snippet text(facetValue: FacetValue)}
  <Text {...facetValue} {...facetValueLabelOptions} />
{/snippet}

{#snippet checkbox(facetValue: FacetValue)}
  <Checkbox {...facetValue} {...facetValueOptions}>
    {@render label(facetValue)}
  </Checkbox>
{/snippet}

{#snippet chip(facetValue: FacetValue)}
  <Chip {...facetValue} {...facetValueOptions}>
    {@render label(facetValue)}
  </Chip>
{/snippet}

{#snippet radio(facetValue: FacetValue)}
  <Radio {...facetValue} {...facetValueOptions}>
    {@render label(facetValue)}
  </Radio>
{/snippet}

{#snippet grid(values: FacetValue[])}
  <Grid {values} {...facetValueGroupOptions} />
{/snippet}

{#snippet list(values: FacetValue[], valueStatus: FacetValueStatus | undefined)}
  <List {values} {valueStatus} {...facetValueGroupOptions} />
{/snippet}

{#snippet overflowBox(values: FacetValue[])}
  <OverflowBox {values} {...facetValueGroupOptions} />
{/snippet}

{#snippet scrollable(facet: Facet)}
  <Scrollable class="find_facet" {...facet} {...facetOptions} {...filterOptions} {mode} {parentId} />
{/snippet}

{#snippet simple(facet: Facet)}
  <Simple class="find_facet" {...facet} {...facetOptions} />
{/snippet}

{@render facet({ ...data, featureTracking })}
