<script lang="ts">
  import type { FacetValue } from "../facet-value";
  import type { FacetValueGroup, FacetValueGroupOptions } from "./type.ts";
  import { Checkbox } from "../facet-value";

  interface Props extends FacetValueGroup, FacetValueGroupOptions {}

  let { values = [], title, item = checkbox, valueStatus }: Props = $props();
</script>

{#snippet checkbox(facetValue: FacetValue)}
  <Checkbox {...facetValue} />
{/snippet}

{#if values.length > 0}
  <div class={valueStatus ? `hc_facetValueGroup--${valueStatus}` : ""}>
    {#if title}<h4>{title}</h4>{/if}
    <ul>
      {#each values as facetValue}
        <li>{@render item(facetValue)}</li>
      {/each}
    </ul>
  </div>
{/if}

<style lang="scss">
  h4 {
    margin-bottom: 6px;
    font: var(--oc-semantic-font-copy-75);
    text-transform: uppercase;
    color: var(--oc-semantic-color-text-secondary);
  }
</style>
