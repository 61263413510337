import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { Filter } from "./Filter";
import { element, move, moveAllChildren } from "../util/Utils";
import { filterHeaderButtons } from "../filtersheet/FilterSheetHeaderButtons";
import { FilterAccordion } from "./FilterAccordion";
import { eventQBus } from "../types/EventQBus";
import { createEventMergeId, submitEventTracking } from "../tracking/TrackingUtils";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import { readLegacyLabels } from "../tracking/FeatureTracking";
import { updateSanFilterWhatIf } from "../tracking/WhatIfTracking";
import { updateSanPopularFacetValues } from "./PopularFacetValues";

const FILTER_TITLE_CLASS = "heureka_filterTitle";
const FILTER_TITLE_MODE_MODIFIER_PREFIX = `${FILTER_TITLE_CLASS}--`;
const FILTER_TITLE_MODE_ONLY_TITLE_MODIFIER = `${FILTER_TITLE_MODE_MODIFIER_PREFIX}onlyTitle`;
const FILTER_TITLE_MODE_FULL_MODIFIER = `${FILTER_TITLE_MODE_MODIFIER_PREFIX}full`;
const FILTER_TITLE_MODE_ONLY_POPULAR_VALUES_AND_TITLE_MODIFIER = `${FILTER_TITLE_MODE_MODIFIER_PREFIX}onlyPopularValuesAndTitle`;
const FILTER_TITLE_MODE_ONLY_VALUES_MODIFIER = `${FILTER_TITLE_MODE_MODIFIER_PREFIX}onlyValues`;
const FILTER_TITLE_INVALID_MODIFIER = `${FILTER_TITLE_MODE_MODIFIER_PREFIX}invalid`;

const VALUES_CLASS = `${FILTER_TITLE_CLASS}__values`;
const VALUES_SELECTOR = `.${VALUES_CLASS}`;
const MORE_CLASS = `${FILTER_TITLE_CLASS}__more`;
const MORE_BUTTON_SELECTOR = `.${MORE_CLASS} button`;

export type FilterTitleMode = "onlyTitle" | "full" | "onlyPopularValuesAndTitle" | "onlyValues";

export class FilterTitle {
  static readonly factory = HeurekaElementFactory.byClass(FILTER_TITLE_CLASS, FilterTitle);

  /*               */
  constructor(readonly elem: HTMLElement) {}

  /*                  */

  static filterId(id: string, root?: ParentNode | null) {
    return FilterTitle.factory.pick(`.${FILTER_TITLE_CLASS}[data-filter-id="${id}"]`, root);
  }

  static footer(root?: ParentNode | null) {
    return FilterTitle.factory.pick(`.${FILTER_TITLE_CLASS}.${FILTER_TITLE_CLASS}--footer`, root);
  }

  /*               */

  static prepareAll(fragment: DocumentFragment) {
    FilterTitle.factory.forEach((filterTitle) => filterTitle.prepare(), fragment);
  }

  protected prepare() {
    const oldFilterTitle = FilterTitle.filterId(this.filterId);
    if (oldFilterTitle) {
      this.onlyPopularValuesAndTitleMode = oldFilterTitle.onlyPopularValuesAndTitleMode;
    }
  }

  /*                       */

  get filterId(): string {
    return this.elem.dataset.filterId || "";
  }

  get filterTitle() {
    return this.elem.dataset.filterTitle;
  }

  get accordion() {
    return FilterAccordion.filterId(this.filterId);
  }

  get referencedFilter() {
    return Filter.filterId(this.filterId);
  }

  get filter() {
    return Filter.factory.pick(undefined, this.elem);
  }

  set filter(filter) {
    if (filter) {
      move(filter.form, this.values);
    }
  }

  get filterHeaderButtons() {
    return filterHeaderButtons(this.elem);
  }

  set filterHeaderButtons(filterHeaderButtons) {
    if (filterHeaderButtons) {
      moveAllChildren(filterHeaderButtons, this.filterHeaderButtons);
    }
  }

  get values() {
    return element(VALUES_SELECTOR, this.elem);
  }

  get moreButton() {
    return element(MORE_BUTTON_SELECTOR, this.elem);
  }

  get onlyTitleMode(): boolean {
    return this.elem.classList.contains(FILTER_TITLE_MODE_ONLY_TITLE_MODIFIER);
  }

  get fullMode(): boolean {
    return this.elem.classList.contains(FILTER_TITLE_MODE_FULL_MODIFIER);
  }

  get onlyPopularValuesAndTitleMode(): boolean {
    return this.elem.classList.contains(FILTER_TITLE_MODE_ONLY_POPULAR_VALUES_AND_TITLE_MODIFIER);
  }

  set onlyPopularValuesAndTitleMode(state: boolean) {
    if (state && this.onlyTitleMode) {
      this.mode = "onlyPopularValuesAndTitle";
    }
    if (!state && this.onlyPopularValuesAndTitleMode) {
      this.mode = "onlyTitle";
    }
  }

  set mode(mode: FilterTitleMode) {
    this.elem.classList.remove(
      FILTER_TITLE_MODE_ONLY_TITLE_MODIFIER,
      FILTER_TITLE_MODE_FULL_MODIFIER,
      FILTER_TITLE_MODE_ONLY_POPULAR_VALUES_AND_TITLE_MODIFIER,
      FILTER_TITLE_MODE_ONLY_VALUES_MODIFIER,
    );
    this.elem.classList.add(`${FILTER_TITLE_MODE_MODIFIER_PREFIX}${mode}`);
  }

  set sanPopularFacetValues(value: TrackingLabels["san_PopularFacetValues"]) {
    updateSanPopularFacetValues(this.elem, value);
  }

  set sanFilterWhatIf(value: TrackingLabels["san_FilterWhatIf"]) {
    updateSanFilterWhatIf(this.elem, value);
  }

  attachFilter() {
    const { accordion } = this;
    if (accordion) {
      this.filter = accordion.nestedFilter;
      this.filter?.facets
        .filter((facet) => !facet.facet.classList.contains("hc_facet"))
        .forEach((facet) => {
          facet.toggleCountsVisibility(false);
          facet.popularFacetValuesWrapper?.toggleChipsContainer(true);
        });
      this.filterHeaderButtons = accordion.filterHeaderButtons;
      if (this.fullMode) {
        /*                                                                                                                                      */
        this.filter?.facets
          .filter((facet) => !facet.facet.classList.contains("hc_facet"))
          .filter((facet) => facet.isFullyInlinedMultiselect)
          .forEach((facet) => facet.inline());
      }
    }
  }

  detachFilter() {
    const { accordion } = this;
    if (accordion) {
      accordion.nestedFilter = this.filter;
      accordion.nestedFilter?.facets
        .filter((facet) => !facet.facet.classList.contains("hc_facet"))
        .forEach((facet) => {
          facet.toggleCountsVisibility(true);
          facet.popularFacetValuesWrapper?.toggleChipsContainer(false);
        });
      accordion.filterHeaderButtons = this.filterHeaderButtons;
      if (this.fullMode) {
        accordion.nestedFilter?.facets
          .filter((facet) => !facet.facet.classList.contains("hc_facet"))
          .filter((facet) => facet.isFullyInlinedMultiselect)
          .forEach((facet) => facet.removeInlining());
      }
    }
  }

  openDetailSheet(sanFilterFoldoutActivity: TrackingLabels["san_FilterFoldoutActivity"] = "open") {
    const { filterId, filterTitle } = this;
    const tracking: Partial<TrackingLabels> = {
      san_FilterFoldoutType: filterId,
      san_FilterFoldoutActivity: sanFilterFoldoutActivity,
      ...readLegacyLabels(this.elem),
    };
    submitEventTracking(tracking, createEventMergeId());

    switch (filterId) {
      case "localNavigation":
        eventQBus.emit("ftfind.filterSheet.openNavigation");
        break;
      default:
        eventQBus.emit("ftfind.filterSheet.openDetail", { filterId, filterTitle });
    }
  }

  toggleInlineMode(force = !this.onlyPopularValuesAndTitleMode) {
    this.onlyPopularValuesAndTitleMode = force;
  }

  invalidate() {
    this.elem.classList.add(FILTER_TITLE_INVALID_MODIFIER);
  }
}
