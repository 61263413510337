import type { FilterTitleMode } from "./type.ts";

const FILTER_TITLE_CLASS = "heureka_filterTitle";
const FILTER_TITLE_MODE_MODIFIER_PREFIX = `${FILTER_TITLE_CLASS}--`;
const FILTER_TITLE_MODE_ONLY_TITLE_MODIFIER = `${FILTER_TITLE_MODE_MODIFIER_PREFIX}onlyTitle`;
const FILTER_TITLE_MODE_FULL_MODIFIER = `${FILTER_TITLE_MODE_MODIFIER_PREFIX}full`;
const FILTER_TITLE_MODE_ONLY_POPULAR_VALUES_AND_TITLE_MODIFIER = `${FILTER_TITLE_MODE_MODIFIER_PREFIX}onlyPopularValuesAndTitle`;
const FILTER_TITLE_MODE_ONLY_VALUES_MODIFIER = `${FILTER_TITLE_MODE_MODIFIER_PREFIX}onlyValues`;

export function setFilterTitleMode(parentId: string, filterTitleMode: FilterTitleMode) {
  const parentForm = document.getElementById(parentId);
  const filterTitleWrapper = parentForm?.closest(".heureka_filterTitle");
  if (filterTitleWrapper) {
    filterTitleWrapper.classList.add(`hc_svelte`); /*                                           */
    filterTitleWrapper.classList.remove(
      FILTER_TITLE_MODE_ONLY_TITLE_MODIFIER,
      FILTER_TITLE_MODE_FULL_MODIFIER,
      FILTER_TITLE_MODE_ONLY_POPULAR_VALUES_AND_TITLE_MODIFIER,
      FILTER_TITLE_MODE_ONLY_VALUES_MODIFIER,
    );
    filterTitleWrapper.classList.add(`${FILTER_TITLE_MODE_MODIFIER_PREFIX}${filterTitleMode}`);
  }
}
